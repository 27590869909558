import { createBrowserRouter, RouteObject } from "react-router-dom";
import App from "../../App";
import PosApp from "../../pages/app/PosApp";
import SettingsPage from "../../pages/app/SettingsPage";
import PosCartPage from "../../pages/app/PosCartPage";

export const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/app",
    element: <PosApp />,
    children: [
      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "cart",
        element: <PosCartPage />,
      },
    ],
  },
  {
    path: "*",
    element: <div>Not Found</div>,
  },
] as RouteObject[]);
