import React, { ReactNode } from "react";
import { Grid } from "@mui/material";

import SearchAppBar from "../appBar/SearchAppBar";

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Grid
      container
      sx={{ height: "100%", width: "100%"}}
    >
      <SearchAppBar />
      <Grid sx={{ width: "100%", height: "100%"}}>{children}</Grid>
    </Grid>
  );
};

export default AppLayout;
