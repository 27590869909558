import { Outlet } from "react-router-dom";

import AppLayout from "../../components/layouts/AppLayout";

function PosApp() {

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}

export default PosApp;
