import React, { useState } from "react";
import { Grid } from "@mui/material";

import {
  Br,
  Cut,
  Line,
  Printer,
  Text,
  Row,
  render,
  QRCode,
  Barcode,
  Image,
} from "react-thermal-printer";
import SettingsLayout from "../../components/layouts/InnerPageLayout";

const SettingsPage: React.FC = () => {
  // to save the printer object state
  const [printer, setPrinter] = React.useState<any>(null);

  // on printer state change
  React.useEffect(() => {
    if (printer) {
      // connect to the printer
      printer.gatt
        .connect()
        .then((server: any) => {
          // get the service
          return server.getPrimaryService(
            "000018f0-0000-1000-8000-00805f9b34fb"
          );
        })
        .then((service: any) => {
          // get the characteristic
          return service.getCharacteristic(
            "00002af1-0000-1000-8000-00805f9b34fb"
          );
        })
        .then((characteristic: any) => {
          // write to the printer
          return characteristic.writeValue(new Uint8Array([0x1b, 0x40]));
        })
        .then(() => {
          console.log("Printed");
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [printer]);

  const handleOnClick = () => {
    //ask for permission to use bluetooth
    (navigator as any).bluetooth
      .requestDevice({
        acceptAllDevices: true,
        optionalServices: ["000018f0-0000-1000-8000-00805f9b34fb"],
      })
      .then((device: any) => {
        // save opject for later use
        setPrinter(device);
      });
  };

  const handleOnClickPrint = async () => {
    if (printer) {
      // write to the printer
      printer.gatt
        .connect()
        .then((server: any) => {
          return server.getPrimaryService(
            "000018f0-0000-1000-8000-00805f9b34fb"
          );
        })
        .then((service: any) => {
          return service.getCharacteristic(
            "00002af1-0000-1000-8000-00805f9b34fb"
          );
        })
        .then(async (characteristic: any) => {
          // print ------ 58mm printer print line separator
          // Define the command to print the line separator
          const lineSeparatorCommand = new Uint8Array([0x1b, 0x2d, 0x08]); // Command for printing a line of 8 dots width

          // Define the text to be printed
          const textToPrint = "THE STORE";

          // Convert the text to bytes (assuming ASCII encoding)
          const textBytes = new TextEncoder().encode(textToPrint);

          // Combine the commands and text bytes into a single Uint8Array
          const combinedData = new Uint8Array([
            ...lineSeparatorCommand,
            ...textBytes,
            0x0a,
          ]); // 0x0A is the ASCII code for line feed

          const receipt = (
            <Printer type="epson" width={32}>
              <Line character="-" />
              <Text align="center" size={{ width: 2, height: 2 }}>
                PosMan
              </Text>
              <Line character="-" />
              <Text align="right">right text</Text>
              <Text bold={true}>bold text</Text>
              <Text underline="1dot-thick">underline text</Text>
              <Text invert={true}>invert text</Text>
              <Text size={{ width: 2, height: 2 }}>big size text</Text>
              <QRCode content="https://github.com/seokju-na/react-thermal-printer" />
              <Line />
              <Line character="=" />
              <Barcode type="UPC-A" content="111111111111" />
              <Barcode type="CODE39" content="A000$" />
              <Barcode align="center" type="UPC-A" content="111111111111" />
              <Image src="https://posman.teachmeit.lk/logo192.png" />
              <Image
                align="center"
                src="https://posman.teachmeit.lk/logo192.png"
              />
            </Printer>
          );
          const data: Uint8Array = await render(receipt);
          console.log(data);
          // return characteristic.writeValue(data);
          sendData(characteristic, data);

          // 80mm printer
          // return characteristic.writeValue(new Uint8Array([0x1B, 0x40, 0x1B, 0x21, 0x1, 0x48, 0x65, 0x6C, 0x6C, 0x6F, 0x20, 0x57, 0x6F, 0x72, 0x6C, 0x64, 0x21, 0x0A, 0x0A, 0x0A, 0x0A, 0x0A]));

          // 58mm printer
          // return characteristic.writeValue(new Uint8Array([0x1B, 0x40, 0x1B, 0x21, 0x1, 0x48, 0x65, 0x6C, 0x6C, 0x6F, 0x20, 0x57, 0x6F, 0x72, 0x6C, 0x64, 0x21, 0x0A, 0x0A, 0x0A, 0x0A, 0x0A]));

          // return characteristic.writeValue(new Uint8Array([0x1B, 0x40, 0x48, 0x65, 0x6C, 0x6C, 0x6F, 0x20, 0x57, 0x6F, 0x72, 0x6C, 0x64, 0x21, 0x0A, 0x0A, 0x0A, 0x0A, 0x0A]));
        })
        .then(() => {
          console.log("Printed");
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  async function sendData(characteristic: any, data: any) {
    const maxChunkSize = 512;
    let offset = 0;

    while (offset < data.length) {
      const chunk = data.slice(offset, offset + maxChunkSize);
      await characteristic.writeValue(chunk);
      offset += maxChunkSize;
    }
  }

  return (
    <SettingsLayout pageHeader="Settings">
      Bluetooth Printer
      <button onClick={handleOnClick}>Config Printer</button>
      <button onClick={handleOnClickPrint}>Print</button>
    </SettingsLayout>
  );
};

export default SettingsPage;
