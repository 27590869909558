import React, { ReactNode } from "react";

import BackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Typography } from "@mui/material";

interface SettingsLayoutProps {
  children: ReactNode;
  pageHeader: string;
}

const InnerPageLayout: React.FC<SettingsLayoutProps> = ({ children, pageHeader }) => {
  return (
    <Grid
      container
      direction="column"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: 4,
      }}
    >
      <Grid
        item
        sx={{
          width: { lg: "600px", sm: "100%", xs: "100%" },
        }}
      >
        <Box
          sx={{
            px: { lg: 2, sm: 2, xs: 2 },
          }}
        >
          <Typography variant="h5">{pageHeader}</Typography>
        </Box>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton>
                <BackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">Back</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            px: { lg: 2, sm: 2, xs: 2 },
            padding: { lg: 2, sm: 2, xs: 2 },
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InnerPageLayout;
