import React from "react";
import logo from "./logo.svg";
import "./App.css";

import AppLayout from "./components/layouts/AppLayout";

function App() {

  return (
    <AppLayout>
     <p>Hell</p>
    </AppLayout>
  );
}

export default App;
