import { Grid } from "@mui/material";
import React from "react";

const PosCartPage: React.FC = () => {
  return (
    <Grid container sx={{
        height: "100%",
        width: "100%",
        p: 4,
        backgroundColor: "red"
    }}>
      {/* Page layou devide to full height and width and devide to two */}
      {/* First part is for the cart items */}
      <Grid item xs={12} md={8}>
        <h1>Cart Items</h1>
        {/* Add your cart items here */}
      </Grid>
      {/* Second part is for the cart summary */}
      <Grid item xs={12} md={4}>
        <h1>Cart Summary</h1>
        {/* Add your cart summary here */}
      </Grid>
    </Grid>
  );
};

export default PosCartPage;
